waitForJQuery();

function waitForJQuery() {
    if (window.jQuery && window.jQuery().slider && window.jQuery.fn.featherlight && window.jQuery.fn.slick && window.jQuery.fn.lightGallery) {
        init(window.jQuery);
        document.documentElement.classList.remove('is--loading');
    } else {
        setTimeout(waitForJQuery, 50);
    }
}

/**
 * @param {jquery} $
 */
function init($) {
    filterObjectbyType();
    singleObjectInit();
    function GoogleMap(gmap) {
        this.googleMap = gmap;

        this.markers = [];

        this.style = [
            {
                'featureType': 'administrative.locality',
                'elementType': 'all',
                'stylers': [{ 'hue': '#2c2e33' }, { 'saturation': 7 }, { 'lightness': 19 }, { 'visibility': 'on' }],
            }, {
                'featureType': 'landscape',
                'elementType': 'all',
                'stylers': [
                    { 'hue': '#fff' },
                    { 'saturation': -100 },
                    { 'lightness': 100 },
                    { 'visibility': 'simplified' },
                ],
            }, {
                'featureType': 'poi',
                'elementType': 'all',
                'stylers': [{ 'hue': '#fff' }, { 'saturation': -100 }, { 'lightness': 100 }, { 'visibility': 'off' }],
            }, {
                'featureType': 'road',
                'elementType': 'geometry',
                'stylers': [
                    { 'hue': '#bbc0c4' },
                    { 'saturation': -93 },
                    { 'lightness': 31 },
                    { 'visibility': 'simplified' },
                ],
            }, {
                'featureType': 'road',
                'elementType': 'labels',
                'stylers': [{ 'hue': '#bbc0c4' }, { 'saturation': -93 }, { 'lightness': 31 }, { 'visibility': 'on' }],
            }, {
                'featureType': 'road.arterial',
                'elementType': 'labels',
                'stylers': [
                    { 'hue': '#bbc0c4' },
                    { 'saturation': -93 },
                    { 'lightness': -2 },
                    { 'visibility': 'simplified' },
                ],
            }, {
                'featureType': 'road.local',
                'elementType': 'geometry',
                'stylers': [
                    { 'hue': '#e9ebed' },
                    { 'saturation': -90 },
                    { 'lightness': -8 },
                    { 'visibility': 'simplified' },
                ],
            }, {
                'featureType': 'transit',
                'elementType': 'all',
                'stylers': [{ 'hue': '#e9ebed' }, { 'saturation': 10 }, { 'lightness': 69 }, { 'visibility': 'on' }],
            }, {
                'featureType': 'water',
                'elementType': 'all',
                'stylers': [
                    { 'hue': '#e9ebed' },
                    { 'saturation': -78 },
                    { 'lightness': 67 },
                    { 'visibility': 'simplified' },
                ],
            },
        ];

        this.stdArgs = {
            zoom: 16,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false,
            styles: this.style,
        };
    }


    GoogleMap.maps = [];

    GoogleMap.Marker = function(lat, lng, html) {
        this.lat = lat;
        this.lng = lng;
        this.html = html || '';
    };
    GoogleMap.Marker.create = function(lat, lng, html) {
        return new GoogleMap.Marker(lat, lng, html || '');
    };

    GoogleMap.create = function(element, markers) {
        var map = new GoogleMap(new google.maps.Map(element, this.stdArgs));
        if (markers && typeof markers.length !== 'undefined') {
            markers.forEach(function(marker) {
                map.addMarker(marker);
            });
        }
        map.center();
        GoogleMap.maps.push(map);
        return map;
    };

    GoogleMap.$create = function(jQueryElement, markers) {
        return GoogleMap.create(jQueryElement.get(0), markers);
    };

    GoogleMap.createFromAcf = function($el) {
        return GoogleMap.create($el[0], $el.find('.marker').toArray().map(function(marker) {
            var $marker = $(marker);
            return new GoogleMap.Marker($marker.attr('data-lat'), $marker.attr('data-lng'), $marker.html());
        }));
    };


    GoogleMap.prototype.addMarker = function(inputMarker) {
        var self = this;

        var latlng = new google.maps.LatLng(inputMarker.lat, inputMarker.lng);

        // create marker
        var marker = new google.maps.Marker({
            position: latlng,
            map: this.googleMap,
            icon: {
                anchor: new google.maps.Point(16, 16),
                url: 'data:image/svg+xml;utf-8, \
                  <svg width="24" height="35" viewBox="0 0 18 25" xmlns="http://www.w3.org/2000/svg"> \
                    <path fill="#00529C" d="M 8.75 11.875C 7.9212 11.875 7.12634 11.5458 6.54029 10.9597C 5.95424 10.3737 5.625 9.5788 5.625 8.75C 5.625 7.9212 5.95424 7.12634 6.54029 6.54029C 7.12634 5.95424 7.9212 5.625 8.75 5.625C 9.5788 5.625 10.3737 5.95424 10.9597 6.54029C 11.5458 7.12634 11.875 7.9212 11.875 8.75C 11.875 9.16038 11.7942 9.56674 11.6371 9.94589C 11.4801 10.325 11.2499 10.6695 10.9597 10.9597C 10.6695 11.2499 10.325 11.4801 9.94589 11.6371C 9.56674 11.7942 9.16038 11.875 8.75 11.875ZM 8.75 1.11022e-15C 6.42936 1.11022e-15 4.20376 0.921872 2.56282 2.56282C 0.921872 4.20376 3.33067e-15 6.42936 2.22045e-15 8.75C 4.44089e-15 15.3125 8.75 25 8.75 25C 8.75 25 17.5 15.3125 17.5 8.75C 17.5 6.42936 16.5781 4.20376 14.9372 2.56282C 13.2962 0.921872 11.0706 2.77556e-15 8.75 1.11022e-15Z"></path> \
                  </svg>',
            },
        });

        // add to array
        this.markers.push(marker);

        // if marker contains HTML, add it to an infoWindow
        if (inputMarker.html) {
            // create info window
            var infowindow = new google.maps.InfoWindow({
                content: inputMarker.html,
            });
            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function() {
                infowindow.open(self.googleMap, marker);
            });
        }
    };

    GoogleMap.prototype.center = function() {
        var self = this;
        var bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each(this.markers, function(i, marker) {
            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
            bounds.extend(latlng);
        });

        // only 1 marker?
        if (this.markers.length === 1) {
            // set center of map
            this.googleMap.setCenter(bounds.getCenter());
            this.googleMap.setZoom(16);
        } else {
            // fit to bounds
            this.googleMap.fitBounds(bounds);
        }
    };


    $('.acf-map').each(function() {
        GoogleMap.createFromAcf($(this));
    });


    $('.showLocationMarker').on('click', function(event) {
        event.preventDefault();
        var $this = $(this);

        var lat = $this.data('lat'), lng = $this.data('lng');
        var wrapper = document.createElement('div');
        wrapper.className = 'showLocationMarker-map';

        var feather = $.featherlight($(wrapper));

        var map = GoogleMap.$create(feather.$content, [GoogleMap.Marker.create(lat, lng)]);
    });


    var slider = $('.slider');

    slider.slick({
        arrows: false,
        dots: true,
        fade: true,
        speed: 1000,
        cssEase: 'linear',
        autoplay: true,
        infinite: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
    });

    slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        var numberOfSlides = slick.$slides.length;
        var prev = slick.$slides[currentSlide % numberOfSlides];
        var curr = slick.$slides[nextSlide];
        $(this).find('.slide').removeClass('prev');
        $(this).find('.slide').removeClass('curr');
        $(prev).addClass('prev');
        $(curr).addClass('curr');
    });

    slider.on('init', function(slick) {
        $(this).find('.slick-active').addClass('curr');
    });


    // slider dots position
    function sliderDotsPosition() {
        if ($('.slider__container').offset()) {
            var dotsOffset = $('.slider__container').offset().left;
            $('.slick-dots').css('right', dotsOffset + 20 + 'px');
        }
    }

    sliderDotsPosition();
    $(window).on('resize', sliderDotsPosition);


    // scroll to next section
    $('.slider__scrolldown').on('click', function(e) {
        e.preventDefault();
        var parentSec = $(this).closest('section');
        $('html, body').animate({
            scrollTop: parentSec.next().offset().top,
        }, 800);
    });


    // sticky header
    var navOffset = $('.main-navigation').offset().top;

    function stickyHeader() {
        if (window.pageYOffset > navOffset) {
            $('.main-navigation').addClass('sticky');
            $('.site-logo--small').addClass('sticky');
        } else {
            $('.main-navigation').removeClass('sticky');
            $('.site-logo--small').removeClass('sticky');
        }
    }

    stickyHeader();
    $(window).on('scroll', stickyHeader);


    // menu slide line
    $('#primary-menu > li > a').mouseenter(function() {
        var leftPos = $(this).offset().left - $('.menu__wrapper').offset().left,
            width   = $(this).width();
        $('.menu__slide-line').css('left', leftPos + 'px');
        $('.menu__slide-line').css('width', width + 'px');
        $('.menu__slide-line').css('opacity', 1);
    });
    $('#primary-menu').mouseleave(function() {
        $('.menu__slide-line').css('opacity', 0);
    });


    // menu toggle
    $('.menu-toggle').on('click', function(e) {
        e.preventDefault();
        $('.menu-toggle').toggleClass('cross');
        $('.menu__wrapper').toggleClass('shown');
    });


    // object filter

    var region  = $('#obj-region').html(),
        type    = $('#obj-type').html(),
        floor   = $('#obj-floor').html(),
        room    = $('#obj-room').html(),
        rentMin = Number($('#object-rent-min').val()),
        rentMax = Number($('#object-rent-max').val()),
        areaMin = Number($('#object-area-min').val()),
        areaMax = Number($('#object-area-max').val());

    function objectAjaxFilter() {
        $.ajax({
            type: 'post',
            url: ('/wp-admin/admin-ajax.php'),
            data: {
                action: 'filter_objects',
                region: region,
                type: type,
                floor: floor,
                room: room,
                rentMin: rentMin,
                rentMax: rentMax,
                areaMin: areaMin,
                areaMax: areaMax,
            },
        }).done(
            function(data) {
                if (data.success) {
                    $('.objects__container').find('.object').addClass('hidden');

                    $('ul').find('[data-filter="type"]:nth-child(n+2) a').addClass('inactive');
                    $('ul').find('[data-filter="floor"]:nth-child(n+2) a').addClass('inactive');
                    $('ul').find('[data-filter="room"]:nth-child(n+2) a').addClass('inactive');


                    data.ids.forEach(function(item) {
                        $('[data-object-id="' + item + '"]').removeClass('hidden');
                        $('[data-object-id="' + item + '"]').removeClass('faded');
                    });

                    data.type_options.forEach(function(item) {
                        $('[data-filter="type"] a').each(function() {
                            if (item === $(this).text()) {
                                $(this).removeClass('inactive');
                            }
                        });
                    });

                    data.floor_options.forEach(function(item) {
                        $('[data-filter="floor"] a').each(function() {
                            if (item === $(this).text()) {
                                $(this).removeClass('inactive');
                            }
                        });
                    });

                    data.room_options.forEach(function(item) {
                        $('[data-filter="room"] a').each(function() {
                            if (item === $(this).text()) {
                                $(this).removeClass('inactive');
                            }
                        });
                    });

                    $('.obj-filter__info span').html(data.ids.length);

                } else {
                    $('.objects__container').empty();
                    $('.objects__container').append('<h1>No objects</h1>');
                }
            },
        );
    }

    $('.obj-filter__value').on('click', function(e) {
        e.preventDefault();
        $(this).next().toggleClass('shown');
    });

    $('.obj-filter__option').on('click', function(e) {
        e.preventDefault();
        $(this).closest('ul').removeClass('shown');
        $(this).closest('ul').prev().empty();
        $(this).closest('ul').prev().append($(this).html());

        if (!$(this).hasClass('obj-filter__option--modal')) {

            $('.objects__container').find('.object').addClass('faded');

            region = $('#obj-region').html();
            type = $('#obj-type').html();
            floor = $('#obj-floor').html();
            room = $('#obj-room').html();

            objectAjaxFilter();
        }
    });

    // reset ajax button
    $('.obj-filter__reset').on('click', function(e) {
        e.preventDefault();
        window.location.reload();
    });


    // jquery ui slider
    $('#object-rent').slider({
        range: true,
        min: rentMin,
        max: rentMax,
        values: [rentMin, rentMax],
        create: function() {
            var values = $('#object-rent').slider('values');
            $('#object-rent-min').val(values[0]);
            $('#object-rent-max').val(values[1]);
            rentMin = $('#object-rent-min').val();
            rentMax = $('#object-rent-max').val();
        },
        slide: function(event, ui) {
            $('#object-rent-min').val(ui.values[0]);
            $('#object-rent-max').val(ui.values[1]);
            rentMin = $('#object-rent-min').val();
            rentMax = $('#object-rent-max').val();
        },
        change: function() {
            $('.objects__container').find('.object').addClass('faded');
            objectAjaxFilter();
        },
    });

    $('.range-slider__val').on('change', function() {
        rentMin = $('#object-rent-min').val();
        rentMax = $('#object-rent-max').val();
        areaMin = $('#object-area-min').val();
        areaMax = $('#object-area-max').val();

        $('#object-rent').slider('values', [rentMin, rentMax]);
        $('#object-area').slider('values', [areaMin, areaMax]);
    });

    $('#object-area').slider({
        range: true,
        min: areaMin,
        max: areaMax,
        values: [areaMin, areaMax],
        create: function() {
            var values = $('#object-area').slider('values');
            $('#object-area-min').val(values[0]);
            $('#object-area-max').val(values[1]);
            areaMin = $('#object-area-min').val();
            areaMax = $('#object-area-max').val();
        },
        slide: function(event, ui) {
            $('#object-area-min').val(ui.values[0]);
            $('#object-area-max').val(ui.values[1]);
            areaMin = $('#object-area-min').val();
            areaMax = $('#object-area-max').val();
        },
        change: function() {
            $('.objects__container').find('.object').addClass('faded');
            objectAjaxFilter();
        },
    });


    // lightGallery
    $(document).ready(function() {
        $('#lightgallery').lightGallery();
    });


    function inputsReaction(input) {
        if ($(input).val() || $(input).is(':focus')) {
            $(input).closest('.cf-item').addClass('active');
        } else {
            $(input).closest('.cf-item').removeClass('active');
        }
    }

    // contact form inputs
    $('.cf-item__input').each(function(i, input) {
        $(input).on('focus blur', function() {
            inputsReaction(input);
        });
        inputsReaction(input);
    });


    function checkboxReaction(checkbox) {
        console.log('checkbox reaction');
        if ($(checkbox).is(':checked')) {
            $(checkbox).closest('.cf-checkbox').addClass('checked');
        } else {
            $(checkbox).closest('.cf-checkbox').removeClass('checked');
        }
    }

    $('#cf-checkbox input[type="checkbox"]').each(function(i, checkbox) {
        checkboxReaction(checkbox);
        $(checkbox).on('click', function() {
            checkboxReaction(checkbox);
        });
    });


    // related objects type filter

    var projId = $('.type-filter').attr('data-proj-id');
    var postId = $('.type-filter').attr('data-post-id');
    window.relatedObjIDs = [];
    $('input[name="object-type"]').on('click', function() {
        var inputs = $('input[name="object-type"]:checked');
        var types = [];
        for (var i = 0; i < inputs.length; i++) {
            types[i] = inputs[i].value;
        }
        $.ajax({
            type: 'post',
            url: ('/wp-admin/admin-ajax.php'),
            data: {
                action: 'filter_related_objects',
                types: types,
                projId: projId,
                postId: postId,
            },
        }).done(
            function(data) {
                if (data.success) {
                    $('.objects__container').empty();
                    $('.objects__container').append(data.content);
                    $('.button.objects__link span').html(data.quantity);
                    window.relatedObjIDs = data.allPostIDs;
                    if (data.show_button === true) {
                        $('#all-related-objects').css('display', 'inline-block');
                    } else {
                        $('#all-related-objects').css('display', 'none');
                    }


                } else {
                    $('.objects__container').empty();
                    $('.objects__container').append('<h1>No objects</h1>');
                }
            },
        );
    });

    $('#all-related-objects').on('click', function(e) {
        e.preventDefault();
        $.ajax({
            type: 'post',
            url: ('/wp-admin/admin-ajax.php'),
            data: {
                action: 'show_all_related_objects',
                relObjIDs: window.relatedObjIDs,
                projId: projId,
                postId: postId,
            },
        }).done(
            function(data) {
                $('.objects__container').append(data.content);
                $('#all-related-objects').css('display', 'none');
            },
        );
    });


    // scroll to related objects ('visa' on single-project page)
    $('#scroll-to-obj').on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $('.objects').offset().top - 50,
        }, 800);
    });


    // projects ajax filter
    $('input[name="project-type"]').on('click', function() {
        var inputs = $('input[name="project-type"]:checked');
        var regions = [];
        for (var i = 0; i < inputs.length; i++) {
            regions[i] = inputs[i].value;
        }
        $('#js-proj-container').find('.project').addClass('fading');
        $.ajax({
            type: 'post',
            url: ('/wp-admin/admin-ajax.php'),
            data: {
                action: 'wa_filter_projects',
                regions: regions,
            },
        }).done(
            function(data) {
                $('#js-proj-container').find('.fading').remove();
                if (data.content) {
                    var $html = $($.parseHTML(data.content));
                    $html.addClass('hidden');
                    $('#js-proj-container').prepend($html).find('.project').each(function(i, card) {
                        setTimeout(function() {
                            $(card).removeClass('hidden');
                        }, 400 * i);
                    });
                } else {

                }
            },
        );
    });


    // faq block switcher
    $('.faq-block__question').on('click', function(e) {
        e.preventDefault();
        var postId = $(this)[0].dataset.post_id;
        document.querySelector('.faq-block__question.current_item').classList.remove('current_item');
        document.querySelector('.faq-block__answer.current_answer').classList.remove('visible');
        setTimeout(function() {
            document.querySelector('.faq-block__answer.current_answer').classList.remove('current_answer');
        }, 300);
        setTimeout(function() {
            document.querySelector('.faq-block__answer[data-answer-id="' + postId + '"]').classList
                .add('current_answer');

        }, 300);
        setTimeout(function() {
            document.querySelector('.faq-block__answer[data-answer-id="' + postId + '"]').classList.add('visible');
        }, 400);
        $(this).addClass('current_item');
    });

    // modal window
    $('#modal-select + ul a').on('click', function() {
        var termId = $(this).attr('data-term-id');
        $('.modal-window__inner').addClass('overflow');
        $('.modal-window__person.shown').removeClass('shown');
        $('.modal-window__form.shown').removeClass('shown');
        $('.modal-window__person[data-term-id="' + termId + '"]').addClass('shown');
        $('.modal-window__form[data-term-id="' + termId + '"]').addClass('shown');
    });

    // modal window
    $('.show-modal').on('click', function(e) {
        e.preventDefault();
        $('.modal-window').addClass('shown');
    });

    $('#close-modal').on('click', function(e) {
        e.preventDefault();
        $('.modal-window').removeClass('shown');
    });


    // modal window hover button
    $('.show-modal__inner').on('mouseover', function() {
        $(this).addClass('scaled');
    });
    $('.show-modal__inner').on('transitionend', function() {
        $(this).removeClass('scaled');
    });


    //modal window inputs
    $('.mcf__input').each(function() {
        $(this)[0].addEventListener('focus', function() {
            $(this).closest('.mcf__item').addClass('active');
        });
        $(this)[0].addEventListener('blur', function() {
            if (!$(this).val()) {
                $(this).closest('.mcf__item').removeClass('active');
            }
        });
    });


    // auto resize text area
    $.each($('textarea.cf-item__input'), function() {
        var offset = this.offsetHeight - this.clientHeight;

        var resizeTextarea = function(el) {
            $(el).css('height', 'auto').css('height', el.scrollHeight + offset);
            $('.modal-window__inner').scrollTop($('.modal-window__inner').get(0).offsetHeight);
        };
        $(this).on('keyup input', function() {
            resizeTextarea(this);
        });
    });

    $('.modal-window__form').each(function(i, f) {
        var $f      = $(f),
            button  = $f.find('button.modal-window__formToggler'),
            wrapper = $f.find('.modal-window__formWrapper');

        wrapper.css({ height: 0 });

        button.on('click', function() {
            if (button.hasClass('s-open')) {
                button.removeClass('s-open');
                wrapper.css({ height: 0 });
            } else {
                button.addClass('s-open');
                wrapper.css({ height: wrapper.get(0).scrollHeight + 'px' });
            }
        });
    });


    var $interestForm = $('form.waifForm');
    $interestForm.on('requestSuccess', function(payload, def) {
        $interestForm.addClass('sentSuccessfully');
    });

    (function(root) {
        var textarea  = root.find('textarea'),
            counter   = root.find('.charCounter'),
            currentEl = counter.find('.charCounter-current'),
            limitEl   = counter.find('.charCounter-limit'),
            limit     = parseInt(counter.data('limit')) || 100;

        limitEl.html(limit);

        textarea.on('input', function() {
            var text      = textarea.val(),
                trancated = text,
                cursor    = textarea.prop('selectionStart');

            text.length >= limit
            ? counter.addClass('s-reached-limit')
            : counter.removeClass('s-reached-limit');

            if (text.length > limit) {
                trancated = text.split('').slice(0, limit).join('');
                textarea.val(trancated);
                if (cursor && cursor < limit) {
                    textarea.prop('selectionStart', cursor).prop('selectionEnd', cursor);
                }
            }
            currentEl.html(trancated.length);
        });
    }($('.obj-form .cf-item--textarea')));
    
    function filterObjectbyType() {
        let $filter = $("[data-filter=\"only-type\"]");
        
        $filter.on('click', function(e) {
            $filter.removeClass('is--active');
            $(this).addClass('is--active');
            
            if (!$(this).hasClass('obj-filter__option--modal')) {
                
                $('.objects__container').find('.object').addClass('faded');
                $.ajax({
                    type: 'post',
                    url: ('/wp-admin/admin-ajax.php'),
                    data: {
                        action: 'filter-objects-by-type',
                        type: $(this).html(),
                    },
                }).done(
                  function(data) {
                      if (data.success) {
                          $('.objects__container').find('.object').addClass('hidden');
                          
                          data.ids.forEach(function(item) {
                              $('[data-object-id="' + item + '"]').removeClass('hidden');
                              $('[data-object-id="' + item + '"]').removeClass('faded');
                          });
                          
                      } else {
                          $('.objects__container').empty();
                          $('.objects__container').append('<h1>No objects</h1>');
                      }
                  },
                );
            }
            
        });
    }
    
    function singleObjectInit() {
        const modalBtnOpen = $(".obj-facts__modalBtn");
        const modalBtnClose = $(".obj-form__close");
        const form = $(".obj-form");
        
        if (!modalBtnOpen) {
            return;
        }
        
        modalBtnOpen.on("click", function (e) {
            form.addClass("is--open");
        });
        
        modalBtnClose.on("click", function (e) {
            form.removeClass("is--open");
        });
    }
    
}
